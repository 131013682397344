@import './Imports/Colours.scss';
@import './Imports/Breakpoints.scss';
.home-header {
  height: 87vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Kode Mono", monospace;

  @media (min-height: $smh) {
    height: 90vh;
  }
  @media (min-height: $mdh) {
    height: 92vh;
  }
  @media (min-height: $lgh) {
    height: 95vh;
  }
}

.home-section {
  width: 100%;
  min-height: 400px;
  border-top: 1px solid black;
}
.home-about, .home-projects, .home-contact {
  display: flex;
  align-items: center;
  justify-content: center;
}

.waviy {
  position: relative;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: #000;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(.2s * var(--i));

  @media (min-width: $smh) {
    font-size: 50px;
  }
  @media (min-width: $mdh) {
    font-size: 70px;
  }
  @media (min-width: $lgh) {
    font-size: 100px;
  }
}
@keyframes flip {
  0%,80% {
    transform: rotateY(360deg)
  }
}