@import './Imports/Breakpoints.scss';
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

.body-container {
  margin-top: 13vh;

  @media (min-height: $smh) {
    margin-top: 10vh;
  }
  @media (min-height: $mdh) {
    margin-top: 8vh;
  }
  @media (min-height: $lgh) {
    margin-top: 5vh;
  }
}